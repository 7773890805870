import React from 'react';

export default function Actualites() {
  return (
    <div className="text-white space-y-8 px-6 py-8 max-w-4xl mx-auto">
      <h2 className="text-3xl font-bold text-center">🗞️ Actualités Skyrock</h2>

      {/* ACTU KEBLACK */}
      <article className="bg-black/50 p-6 rounded-lg shadow-lg space-y-4 border border-white/10">
        <img
          src="https://skyrock.fm/uploads2020/image/resize-crop/news/news-50435.NGI5ZmM3NDcwODllYmIwNDJiNWRjNWMxYzU2ZGY5NjYwOGUzYzkwYTQ1MDQ3ZDdhNTUzNjQ3ZjhhNGNmMTNiNQ.jpg?resize_w=2880"
          alt="Keblack Zénith"
          className="rounded-lg w-full object-cover shadow"
        />
        <h3 className="text-xl font-bold">KEBLACK EN LARMES LORS DE SON ZÉNITH</h3>
        <p className="text-sm text-white/70">📅 Le 07-04-2025</p>
        <p>
          Ce vendredi 4 avril, Keblack débute sa tournée avec un Zénith de Paris bouillant. Devant une salle pleine à craquer,
          l’artiste a laissé parler son cœur… jusqu’à verser quelques larmes d’émotion. Ce concert, c’était plus qu’un show :
          un vrai retour aux sources. La preuve ? Il a ramené sur scène la même doudoune qui l’accompagnait à ses débuts dans la musique.
          Symbole fort pour un artiste qui n’a jamais oublié d’où il vient.
        </p>

        <img
          src="https://skyrock.fm/uploads2020/image/resize-crop/news/news-50433.YjkxMmMwMDI4YmU2YjE1MGU3Y2E1MzUwYWJmZjliYTkyYWM0YmZiODJjMmY0MDE3YWRlNmFkMThiZTBmNDRkZA.png?resize_w=2880"
          alt="Keblack Show"
          className="rounded-lg w-full object-cover shadow mt-4"
        />
        <p>
          Keblack n’était pas seul pour cette grande fête. Il a ramené du lourd avec Gradur, Yaro, L2B, Franglish, Heuss L’Enfoiré
          ou encore Guy2Bezbar pour enflammer la scène. Une soirée légendaire !
        </p>
        <p className="text-yellow-400 font-semibold mt-2">🔥 Un concert Skyrock</p>
      </article>

      {/* TOP ALBUMS */}
      <article className="bg-black/50 p-6 rounded-lg shadow-lg space-y-4 border border-white/10">
        <h3 className="text-xl font-bold">LE TOP ALBUMS DE LA SEMAINE !</h3>
        <p className="text-sm text-white/70">📅 Le 07-04-2025</p>
        <img
          src="https://skyrock.fm/uploads2020/image/resize-crop/news/news-50433.YjkxMmMwMDI4YmU2YjE1MGU3Y2E1MzUwYWJmZjliYTkyYWM0YmZiODJjMmY0MDE3YWRlNmFkMThiZTBmNDRkZA.png?resize_w=2880"
          alt="Top Albums"
          className="rounded-lg w-full object-cover shadow"
        />
        <p>
          Découvrez le classement de la semaine avec VALD, GIMS, JAZZY BAZZ, WERENOI, KEBLACK et bien d'autres !
        </p>
      </article>

      {/* TOP SINGLES */}
      <article className="bg-black/50 p-6 rounded-lg shadow-lg space-y-4 border border-white/10">
        <h3 className="text-xl font-bold">LE TOP SINGLES DE LA SEMAINE !</h3>
        <p className="text-sm text-white/70">📅 Le 07-04-2025</p>
        <img
          src="https://skyrock.fm/uploads2020/image/resize-crop/news/news-50431.YWQ0OGFiMTVhOWFiNWI1NTY2YTg5NWIwMDY3ZWE2ZDhkNDBjNmVlNzVmNWQ4NzRmOWY5MDg5NTAzYmZjOTYxYQ.jpg?resize_w=2880"
          alt="Top Singles"
          className="rounded-lg w-full object-cover shadow"
        />
        <p>
          GIMS, KEBLACK, DJ KAWEST, ROSÉ & BRUNO MARS, DOECHII, L2B, BOOBA, GAZO, BAD BUNNY, VALD, JUL...
          Le meilleur du son cette semaine dans le Top Singles Skyrock.
        </p>
      </article>

      {/* ROSALÍA OVNI */}
      <article className="bg-black/50 p-6 rounded-lg shadow-lg space-y-4 border border-white/10">
        <h3 className="text-xl font-bold">ROSALÍA EN MODE OVNI 👽</h3>
        <p className="text-sm text-white/70">📅 Le 03-04-2025</p>
        <img
          src="https://skyrock.fm/uploads2020/image/resize-crop/news/news-50427.MDUzOGI4YzE4MTFjNzMxNGUxMzQyZTllNGUzMDdjYWVhYzAzOTI1MTRiNjU4Y2U1OTRiZGYxNWUzODNiZTQ0Zg.jpg?resize_w=2880"
          alt="Rosalía"
          className="rounded-lg w-full object-cover shadow"
        />
        <p>
          Rosalía, icône mondiale entre reggaeton et flamenco 2.0, écoute Jul ! Son style unique traverse les frontières.
          Jul, star malgré lui, s’impose dans les playlists des plus grands.
        </p>
      </article>
    </div>
  );
}
