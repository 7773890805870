import React, { useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from '../firebase';
import {
  collection,
  getDocs,
  addDoc,
  deleteDoc,
  doc
} from 'firebase/firestore';

export default function Admin() {
  const [email, setEmail] = useState('');
  const [users, setUsers] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    onAuthStateChanged(auth, user => {
      setCurrentUser(user);
    });
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    const snapshot = await getDocs(collection(db, 'users'));
    setUsers(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
  };

  const handleAddUser = async () => {
    if (!email) return;
    try {
      await addDoc(collection(db, 'users'), { email });
      alert('Utilisateur ajouté dans la base');
      setEmail('');
      fetchUsers();
    } catch (err) {
      alert('Erreur : ' + err.message);
    }
  };

  const handleDeleteUser = async (id) => {
    try {
      await deleteDoc(doc(db, 'users', id));
      alert('Utilisateur supprimé');
      fetchUsers();
    } catch (err) {
      alert('Erreur suppression : ' + err.message);
    }
  };

  if (!currentUser || currentUser.email !== 'steven@skyrock.com') {
    return <div className="text-white text-center mt-20 text-lg">⛔ Accès refusé</div>;
  }

  return (
    <div className="max-w-xl mx-auto p-6 text-white">
      <h2 className="text-3xl font-bold mb-6 text-center">👤 Admin — Utilisateurs</h2>

      <div className="bg-black/40 p-4 rounded shadow space-y-4">
        <input
          type="email"
          placeholder="Adresse e-mail"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="w-full p-2 rounded bg-gray-800 text-white"
        />
        <button
          onClick={handleAddUser}
          className="w-full bg-blue-600 hover:bg-blue-700 py-2 rounded text-white font-semibold"
        >
          ➕ Ajouter l’utilisateur
        </button>
      </div>

      <div className="mt-8">
        <h3 className="text-xl font-semibold mb-3">📋 Liste des utilisateurs</h3>
        {users.length === 0 ? (
          <p className="text-white/50 italic">Aucun utilisateur enregistré.</p>
        ) : (
          <ul className="space-y-3">
            {users.map(user => (
              <li
                key={user.id}
                className="bg-white/5 px-4 py-3 rounded flex justify-between items-center"
              >
                <span>{user.email}</span>
                <button
                  onClick={() => handleDeleteUser(user.id)}
                  className="text-red-400 hover:text-red-600"
                >
                  Supprimer
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}
