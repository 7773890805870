import React, { useEffect, useState } from 'react';

const Accueil = () => {
  const [time, setTime] = useState(new Date());
  const [weather, setWeather] = useState(null);
  const [pointages, setPointages] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => setTime(new Date()), 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    fetch('https://wttr.in/Paris?format=%t %c')
      .then(res => res.text())
      .then(setWeather)
      .catch(() => setWeather('🌧️ Météo non dispo'));
  }, []);

  const handlePointage = (type) => {
    const now = new Date();
    setPointages([...pointages, `${type} à ${now.toLocaleTimeString()}`]);
  };

  return (
    <div className="text-center text-white space-y-6 pb-28">
      {/* 1. TITRE */}
      <h2 className="text-3xl font-extrabold">Bienvenue sur l'intranet Skyrock</h2>
      <p className="text-white/80">Utilisez les onglets ci-dessus pour accéder aux fonctionnalités de la radio.</p>

      {/* 2. METEO + HEURE */}
      <div className="bg-black/60 inline-block px-6 py-4 rounded shadow-md mt-4">
        <div className="text-white/70 text-sm">🕒 Heure actuelle</div>
        <div className="text-2xl font-mono">{time.toLocaleTimeString()}</div>
        <div className="mt-2 text-white/70">🌤️ Météo : {weather}</div>
      </div>

      {/* 3. POINTAGE */}
      <div className="bg-white/5 backdrop-blur p-4 max-w-lg mx-auto rounded shadow mt-6 space-y-3">
        <h3 className="text-lg font-bold">🕵️‍♂️ Pointage du jour</h3>
        <div className="flex flex-wrap gap-3 justify-center">
          <button onClick={() => handlePointage("🟢 Arrivée")} className="bg-green-500 text-white px-4 py-2 rounded">🟢 Arrivée</button>
          <button onClick={() => handlePointage("🍽️ Pause déjeuner")} className="bg-yellow-500 text-white px-4 py-2 rounded">🍽️ Pause déjeuner</button>
          <button onClick={() => handlePointage("🔴 Fin de journée")} className="bg-red-500 text-white px-4 py-2 rounded">🔴 Fin de journée</button>
        </div>
        <ul className="text-white/80 list-disc pl-5 mt-2">
          {pointages.map((p, i) => <li key={i}>{p}</li>)}
        </ul>
      </div>

      {/* 4. FREQUENCES SKYROCK */}
      <a href="https://skyrock.fm/frequences" target="_blank" rel="noopener noreferrer">
        <img
          src="https://skyrock.pub/media/skyrock_map.png"
          alt="Fréquences Skyrock"
          className="mx-auto w-3/5 hover:opacity-90 transition shadow-lg rounded mt-6"
        />
      </a>

      {/* 5. 🎧 Mini player */}
      <div className="fixed bottom-0 left-0 right-0 bg-black/90 backdrop-blur border-t border-white/10 flex items-center justify-between px-4 py-2 text-white shadow-lg z-50">
        <div className="flex items-center gap-3">
          <img src="https://skyrock.fm/images/skyrock-footer.svg" alt="Skyrock" className="h-8 w-auto" />
          <span className="text-sm font-semibold">Skyrock – Live 🔴</span>
        </div>
        <audio controls className="h-8 w-60">
          <source src="http://icecast.skyrock.net/s/natio_aac_128k" type="audio/aac" />
          Ton navigateur ne supporte pas l'audio HTML5.
        </audio>
      </div>
    </div>
  );
};

export default Accueil;
