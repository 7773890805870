import React, { useEffect, useState, useRef } from 'react';
import '../index.css';

const fakeMessages = [
  { pseudo: 'Alex92', platform: 'Twitter', message: 'Génial la playlist sur #Skyrock ce soir !', date: new Date().toLocaleString() },
  { pseudo: 'Laura_Tk', platform: 'TikTok', message: 'Nouvelle danse sur mon son préféré #Skyrock', date: new Date().toLocaleString() },
  { pseudo: 'Samir', platform: 'SMS', message: 'Dédicace à toute l’équipe #Skyrock !', date: new Date().toLocaleString() },
  { pseudo: 'Ines_insta', platform: 'Instagram', message: 'Live énorme ce soir @Skyrock ! #Skyrock', date: new Date().toLocaleString() },
  { pseudo: 'Fab_App', platform: 'Application Skyrock', message: 'J’écoute non-stop, vous êtes au top ! #Skyrock', date: new Date().toLocaleString() },
  { pseudo: 'Lucas', platform: 'Twitter', message: 'Merci pour le cadeau @Skyrock #Skyrock', date: new Date().toLocaleString() },
  { pseudo: 'Mel_tktok', platform: 'TikTok', message: 'Le challenge est lancé ! #Skyrock', date: new Date().toLocaleString() },
  { pseudo: 'Jessy', platform: 'SMS', message: 'Trop fort le morning ! #Skyrock', date: new Date().toLocaleString() },
  { pseudo: 'Théo_insta', platform: 'Instagram', message: 'Photo du concert dispo sur mon compte ! #Skyrock', date: new Date().toLocaleString() },
  { pseudo: 'Chloe_App', platform: 'Application Skyrock', message: 'Toujours à l’écoute, merci ! #Skyrock', date: new Date().toLocaleString() },
  { pseudo: 'Jordan', platform: 'Twitter', message: 'Super son ce matin sur #Skyrock !', date: new Date().toLocaleString() },
  { pseudo: 'Emma', platform: 'TikTok', message: 'Venez voir mes vidéos sur #Skyrock #Vibes', date: new Date().toLocaleString() },
  { pseudo: 'Roxane', platform: 'SMS', message: 'Envoyez un message pour une dédicace #Skyrock', date: new Date().toLocaleString() },
  { pseudo: 'Luca', platform: 'Instagram', message: 'Écoutez Skyrock, c’est le feu ! #Skyrock', date: new Date().toLocaleString() },
  { pseudo: 'Mélissa', platform: 'Application Skyrock', message: 'Merci de diffuser mes sons ! #Skyrock', date: new Date().toLocaleString() },
  { pseudo: 'Leo_', platform: 'Twitter', message: 'J’adore l’émission de ce soir sur Skyrock !', date: new Date().toLocaleString() },
  { pseudo: 'Mira', platform: 'TikTok', message: 'On danse tous sur #Skyrock #DanceChallenge', date: new Date().toLocaleString() },
  { pseudo: 'Nicolas', platform: 'SMS', message: 'Une dédicace à toute l’équipe ! #Skyrock', date: new Date().toLocaleString() },
  { pseudo: 'Sarah_insta', platform: 'Instagram', message: 'Superbes images de l’événement ce soir ! #Skyrock', date: new Date().toLocaleString() },
  { pseudo: 'Elena_App', platform: 'Application Skyrock', message: 'Skyrock est toujours là pour moi ! #Skyrock', date: new Date().toLocaleString() },
  { pseudo: 'Max123', platform: 'Twitter', message: 'Vive Skyrock, le meilleur son ! #Skyrock', date: new Date().toLocaleString() },
  { pseudo: 'Gwen_Tok', platform: 'TikTok', message: 'Des vidéos fun sur #Skyrock à ne pas manquer !', date: new Date().toLocaleString() },
  { pseudo: 'Theo_SMS', platform: 'SMS', message: 'Merci pour cette super soirée Skyrock !', date: new Date().toLocaleString() },
  { pseudo: 'Emma_Inst', platform: 'Instagram', message: 'Génial le concert ce soir sur #Skyrock !', date: new Date().toLocaleString() },
  { pseudo: 'LucasApp', platform: 'Application Skyrock', message: 'Toujours à l’écoute sur Skyrock #Skyrock', date: new Date().toLocaleString() },
  { pseudo: 'JoeyTweet', platform: 'Twitter', message: 'Skyrock, toujours la meilleure musique !', date: new Date().toLocaleString() },
  { pseudo: 'LolaTik', platform: 'TikTok', message: 'Skyrock m’accompagne partout ! #Skyrock', date: new Date().toLocaleString() },
  { pseudo: 'Mika_Sms', platform: 'SMS', message: 'J’adore les émissions de Skyrock, c’est trop cool !', date: new Date().toLocaleString() },
  { pseudo: 'GabyInsta', platform: 'Instagram', message: 'Le concert de ce soir était incroyable ! #Skyrock', date: new Date().toLocaleString() },
  { pseudo: 'JulesApp', platform: 'Application Skyrock', message: 'Écouter Skyrock, c’est ma routine ! #Skyrock', date: new Date().toLocaleString() },
  { pseudo: 'Lucie_Twitter', platform: 'Twitter', message: 'Skyrock, toujours en top des hits !', date: new Date().toLocaleString() },
  { pseudo: 'Carla_Tok', platform: 'TikTok', message: 'Trop bien Skyrock, toujours dans mes oreilles ! #Skyrock', date: new Date().toLocaleString() },
  { pseudo: 'Samuel_SMS', platform: 'SMS', message: 'Merci pour la super soirée Skyrock !', date: new Date().toLocaleString() },
  { pseudo: 'Lucie_insta', platform: 'Instagram', message: 'Le son de ce soir était de folie ! #Skyrock', date: new Date().toLocaleString() },
  { pseudo: 'Jack_App', platform: 'Application Skyrock', message: 'Écouter Skyrock, c’est le bonheur ! #Skyrock', date: new Date().toLocaleString() },
  { pseudo: 'Ali_tweet', platform: 'Twitter', message: 'Un des meilleurs morceaux ce soir sur #Skyrock !', date: new Date().toLocaleString() },
  { pseudo: 'Nina_tik', platform: 'TikTok', message: 'Je suis en mode #Skyrock toute la journée !', date: new Date().toLocaleString() },
  { pseudo: 'Romain_sms', platform: 'SMS', message: 'Skyrock, c’est toujours un plaisir d’écouter !', date: new Date().toLocaleString() },
  { pseudo: 'Lise_insta', platform: 'Instagram', message: 'C’est incroyable de découvrir ces nouveaux sons sur #Skyrock !', date: new Date().toLocaleString() },
  { pseudo: 'Eva_App', platform: 'Application Skyrock', message: 'Skyrock, c’est ma radio préférée ! #Skyrock', date: new Date().toLocaleString() },
  { pseudo: 'Benjamin_Twitter', platform: 'Twitter', message: 'Skyrock, toujours au top avec les hits !', date: new Date().toLocaleString() },
  { pseudo: 'Charlotte_Tok', platform: 'TikTok', message: 'Skyrock sur TikTok, je suis fan ! #Skyrock', date: new Date().toLocaleString() },
  { pseudo: 'Maxime_SMS', platform: 'SMS', message: 'Super ambiance ce matin sur #Skyrock !', date: new Date().toLocaleString() },
  { pseudo: 'Adeline_insta', platform: 'Instagram', message: 'Les hits de ce soir étaient géniaux ! #Skyrock', date: new Date().toLocaleString() },
  { pseudo: 'Louis_App', platform: 'Application Skyrock', message: 'Skyrock, toujours là pour mettre de l’ambiance !', date: new Date().toLocaleString() },
  { pseudo: 'Camille_tweet', platform: 'Twitter', message: 'Skyrock, la meilleure radio du monde !', date: new Date().toLocaleString() },
  { pseudo: 'Juliette_tik', platform: 'TikTok', message: 'Skyrock, c’est toute ma journée ! #Skyrock', date: new Date().toLocaleString() },
  { pseudo: 'Pierre_sms', platform: 'SMS', message: 'Un grand merci à Skyrock pour cette ambiance de folie !', date: new Date().toLocaleString() },
  { pseudo: 'Sophie_insta', platform: 'Instagram', message: 'Ce soir était top sur Skyrock ! #Skyrock', date: new Date().toLocaleString() },
  { pseudo: 'Marc_App', platform: 'Application Skyrock', message: 'Skyrock, c’est la meilleure radio, tout simplement !', date: new Date().toLocaleString() },
  { pseudo: 'Tom_tweet', platform: 'Twitter', message: 'J’écoute Skyrock toute la journée ! #Skyrock', date: new Date().toLocaleString() },
  { pseudo: 'Mila_tik', platform: 'TikTok', message: 'Skyrock, c’est ma vie ! #Skyrock', date: new Date().toLocaleString() },
  { pseudo: 'Charlotte_sms', platform: 'SMS', message: 'Les émissions de Skyrock sont top !', date: new Date().toLocaleString() },
  { pseudo: 'Clara_insta', platform: 'Instagram', message: 'Skyrock, c’est un son de fou ce soir ! #Skyrock', date: new Date().toLocaleString() },
  { pseudo: 'Pierre_App', platform: 'Application Skyrock', message: 'Skyrock sur mon portable, je suis toujours à l’écoute ! #Skyrock', date: new Date().toLocaleString() },
  // Ajoutez plus de messages ici...
];

const Telex = () => {
  const [messages, setMessages] = useState(fakeMessages);
  const [newPseudo, setNewPseudo] = useState('');
  const [newPlatform, setNewPlatform] = useState('SMS');
  const [newMessage, setNewMessage] = useState('');
  const messagesEndRef = useRef(null);

  // Simule la mise à jour des messages toutes les 10 secondes
  useEffect(() => {
    const interval = setInterval(() => {
      const randomMessage = fakeMessages[Math.floor(Math.random() * fakeMessages.length)];
      setMessages((prevMessages) => [randomMessage, ...prevMessages]);
    }, 10000); // 10 secondes

    return () => clearInterval(interval);
  }, []);

  const handleSubmitMessage = () => {
    if (newPseudo && newMessage) {
      const newMsg = { pseudo: newPseudo, platform: newPlatform, message: newMessage, date: new Date().toLocaleString() };
      setMessages((prevMessages) => [newMsg, ...prevMessages]);
      setNewPseudo('');
      setNewPlatform('SMS');
      setNewMessage('');
    }
  };

  const handleFullScreen = () => {
    const elem = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    }
  };

  return (
    <div className="text-white bg-black p-4 h-screen overflow-y-auto">
      <div className="mb-4">
        <button onClick={handleFullScreen} className="bg-blue-500 text-white py-2 px-4 rounded">
          Plein Écran
        </button>
      </div>

      <div className="space-y-4">
        {/* Messages */}
        {messages.map((msg, index) => (
          <div key={index} className="flex flex-col p-4 mb-2 rounded-lg">
            <div className="bg-blue-500 text-white p-2 rounded-t-lg">
              <strong>{msg.pseudo}</strong> - {msg.platform} - {msg.date}
            </div>
            <div className="bg-black text-yellow-500 p-3 rounded-b-lg text-xl">
              {msg.message}
            </div>
          </div>
        ))}
      </div>

      {/* Interface d'ajout de message */}
      <div className="mt-4 p-4 border-t border-white/10 bg-black">
        <input
          type="text"
          placeholder="Pseudo"
          value={newPseudo}
          onChange={(e) => setNewPseudo(e.target.value)}
          className="bg-gray-800 text-white p-2 rounded w-full mb-2"
        />
        <input
          type="text"
          placeholder="Message"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          className="bg-gray-800 text-white p-2 rounded w-full mb-2"
        />
        <button
          onClick={handleSubmitMessage}
          className="bg-blue-500 text-white py-2 px-4 rounded w-full"
        >
          Ajouter un message
        </button>
      </div>

      <div ref={messagesEndRef} />
    </div>
  );
};

export default Telex;
