import React, { useEffect, useState } from 'react';
import moment from 'moment';
import 'moment/locale/fr';

moment.locale('fr');

const EMISSIONS = [
{
  jours: ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'],
  horaire: '00:00 - 05:00',
  titre: 'RAP & RNB NON STOP',
  animateurs: '',
  image: 'https://skyrock.fm/uploads2020/image/resize-crop/radioemissions/radio_emissions-7.YmQ3NjgzZjkxOTM3OTc1ODFmYzI4Yzc0ZTQzZTM4MDRjOGFhM2Q1YmFjZjk3YjA4YmI1MjUyODBhNDM0OTY3OA.jpg?resize_w=1920'

  },
  {
    jours: ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi'],
    horaire: '05:00 - 06:00',
    titre: 'MORNING BEST',
    animateurs: 'Mehdi',
    image: 'https://skyrock.fm/uploads2020/image/resize-crop/radioemissions/radio_emissions-106.NTVkNmU3NDg4ZTFjZTg1NWQxOTViMzk0MGU1NTM1MTE2MjAzYWFhNTY0MzBjOGRhNzBjMTMzNTIzZTlmZWViYw.jpg?resize_w=1920'
  },
  {
    jours: ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi'],
    horaire: '06:00 - 09:15',
    titre: 'LE MORNING DE DIFOOL',
    animateurs: 'Difool, Romano, Cédric, Carole, Samy, Karim, Rémi',
    image: 'https://skyrock.fm/uploads2020/image/resize-crop/radioemissions/radio_emissions-3.YTgxODk1ZjBmODlhMzBlZTY1ZTU4MmIwZDA2ZmI5MzVjZjE5OTJlMDIyYTBhN2Q0ZjZkYzA3MDBhYmNmMTk4OA.jpg?resize_w=1920'
  },
  {
    jours: ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi'],
    horaire: '09:15 - 12:00',
    titre: 'RAP & RNB NON STOP',
    animateurs: 'Fred',
    image: 'https://skyrock.fm/uploads2020/image/resize-crop/radioemissions/radio_emissions-7.YmQ3NjgzZjkxOTM3OTc1ODFmYzI4Yzc0ZTQzZTM4MDRjOGFhM2Q1YmFjZjk3YjA4YmI1MjUyODBhNDM0OTY3OA.jpg?resize_w=1920'
  },
  {
    jours: ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi'],
    horaire: '12:00 - 16:00',
    titre: 'LES DÉDICACES',
    animateurs: 'Mehdi',
    image: 'https://skyrock.fm/uploads2020/image/resize-crop/radioemissions/radio_emissions-9.OThmMzU2MTA1MzVkNjY1NDAzMDFkZDUwMDRlZWJkZWE1ZDkxYmU1ZmRkN2E3ZjYwYTczNjJiMTQyYjhkNTI4OQ.jpg?resize_w=1920'
  },
  {
    jours: ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi'],
    horaire: '16:00 - 20:00',
    titre: 'LE 16-20',
    animateurs: 'Mrik',
    image: 'https://skyrock.fm/uploads2020/image/resize-crop/radioemissions/radio_emissions-85.NzBlY2EwZDZmZDQ0ZjYyZWI5ZjJmMzFhOTYwZGY4ODcwOWJhMmQ3ODJiYzE1ZWMwYTIyNDEwNTRiNWI1NTgwNw.jpg?resize_w=1920'
  },
  {
    jours: ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Dimanche'],
    horaire: '20:00 - 21:00',
    titre: 'PLANÈTE RAP',
    animateurs: 'Fred',
    image: 'https://skyrock.fm/uploads2020/image/resize-crop/radioemissions/radio_emissions-1.ODE4YmQ0MGQzY2VmMTAxZTBmYWNiMmQ5ODZjNDQyYTkyZjE2N2VkMzk2N2FmY2M4ODVkMDVhMDBkOGQzZjhmNA.jpg?resize_w=1920'
  },
  {
    jours: ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi'],
    horaire: '21:00 - 00:00',
    titre: 'RADIO LIBRE DE DIFOOL',
    animateurs: 'Difool, Romano, Cédric, Marie, Samy, Karim',
    image: 'https://skyrock.fm/uploads2020/image/resize-crop/radioemissions/radio_emissions-15.M2QwZjgwYjcwOTc4NDIwZWZhMjY3YWI1ODg4ZDYyYWJhODBlZTc3MTBiMWY2OWE4N2Q5MWU0NTRmMjc2OWMwOQ.jpg?resize_w=1920'
  },
  {
    jours: ['Vendredi'],
    horaire: '00:00 - 02:00',
    titre: 'LA NOCTURNE',
    animateurs: 'Fred',
    image: 'https://skyrock.fm/uploads2020/image/resize-crop/radioemissions/radio_emissions-25.NDM4YjE3Y2U1MmJhZmIwY2YyMzUxODUwYTY5YmRmZDY4YmRhY2FiYjk5MGY0ZWZjZWIwOTQ3NDBmMWEwOTQ0YQ.jpg?resize_w=1920'
  },
  {
    jours: ['Vendredi'],
    horaire: '02:00 - 06:00',
    titre: 'RAP & RNB NON STOP',
    animateurs: '',
    image: 'https://skyrock.fm/uploads2020/image/resize-crop/radioemissions/radio_emissions-7.YmQ3NjgzZjkxOTM3OTc1ODFmYzI4Yzc0ZTQzZTM4MDRjOGFhM2Q1YmFjZjk3YjA4YmI1MjUyODBhNDM0OTY3OA.jpg?resize_w=1920'
  },
  {
    jours: ['Samedi'],
    horaire: '05:00 - 06:00',
    titre: 'RAP & RNB NON STOP',
    animateurs: '',
    image: 'https://skyrock.fm/uploads2020/image/resize-crop/radioemissions/radio_emissions-7.YmQ3NjgzZjkxOTM3OTc1ODFmYzI4Yzc0ZTQzZTM4MDRjOGFhM2Q1YmFjZjk3YjA4YmI1MjUyODBhNDM0OTY3OA.jpg?resize_w=1920'
  },
  {
    jours: ['Dimanche'],
    horaire: '21:00 - 00:00',
    titre: 'LA SPÉCIALE RADIO LIBRE',
    animateurs: 'Difool et son équipe - Vincent',
    image: 'https://skyrock.fm/uploads2020/image/resize-crop/radioemissions/radio_emissions-29.NjFjNDFkMDc1MzdjZWRhODc1YWZlMjQ3NjIxYzRiNDk3N2JjOTM4MjdkZTg0YTUwNGVjNDFlOTYwYTc1MzUwZA.jpg?resize_w=1920'
  },
];

const parseHoraire = (horaire) => {
  const [start, end] = horaire.split(' - ');
  return {
    start: moment(start, 'HH:mm'),
    end: moment(end, 'HH:mm').isBefore(moment(start, 'HH:mm'))
      ? moment(end, 'HH:mm').add(1, 'day')
      : moment(end, 'HH:mm'),
  };
};

export default function Conducteur() {
  const now = moment();
  const today = now.format('dddd').charAt(0).toUpperCase() + now.format('dddd').slice(1);
  const emissionsDuJour = EMISSIONS.filter(e => e.jours.includes(today));

  const currentEmission = emissionsDuJour.find(e => {
    const { start, end } = parseHoraire(e.horaire);
    return now.isBetween(start, end);
  });

  const emissionsAvenir = emissionsDuJour.filter(e => {
    const { start } = parseHoraire(e.horaire);
    return now.isBefore(start);
  });

  return (
    <div className="text-white space-y-8 px-6 py-8 max-w-4xl mx-auto">
      <h2 className="text-3xl font-bold text-center">📻 Émissions du jour</h2>

      {currentEmission ? (
        <div className="bg-white/10 p-4 rounded-lg shadow text-center">
          <h3 className="text-lg text-green-400 font-semibold">🎙️ Émission en cours</h3>
          <p className="text-xl font-bold">{currentEmission.titre}</p>
          <p className="text-white/70">{currentEmission.horaire} — {currentEmission.animateurs}</p>
          <img src={currentEmission.image} alt={currentEmission.titre} className="rounded-lg mt-4 shadow mx-auto max-h-48" />
        </div>
      ) : (
        <div className="text-center text-red-400">❌ Aucune émission en cours</div>
      )}

      <div>
        <h3 className="text-xl font-bold text-yellow-300 mb-4">📅 À venir aujourd’hui</h3>
        {emissionsAvenir.length > 0 ? emissionsAvenir.map((e, idx) => (
          <div key={idx} className="bg-white/5 p-4 rounded mb-3">
            <p className="text-lg font-semibold">{e.titre}</p>
            <p className="text-white/70">{e.horaire} — {e.animateurs}</p>
            <img src={e.image} alt={e.titre} className="rounded-lg mt-2 max-h-36" />
          </div>
        )) : <p className="text-center italic text-white/50">Aucune émission restante aujourd’hui.</p>}
      </div>
    </div>
  );
}
