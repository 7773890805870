import React, { useState, useEffect } from 'react';
import './index.css';
import Accueil from './pages/Accueil';
import Actualites from './pages/Actualites';
import Telex from './pages/Telex';
import Conducteur from './pages/Conducteur';
import Jeux from './pages/Jeux';
import Appels from './pages/Appels';
import Admin from './pages/Admin';
import Login from './pages/Login';

import { auth } from './firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';

const App = () => {
  const [activeTab, setActiveTab] = useState('accueil');
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (u) => {
      setUser(u);
    });
    return () => unsubscribe();
  }, []);

  const handleLogout = () => {
    signOut(auth);
    setUser(null);
  };

  const tabs = [
    { id: 'accueil', label: 'Accueil' },
    { id: 'actualites', label: 'Actus' },
    { id: 'telex', label: 'Telex' },
    { id: 'conducteur', label: 'Conducteur' },
    { id: 'jeux', label: 'Jeux' },
    { id: 'appels', label: 'Appels' },
  ];

  if (user?.email === 'steven@skyrock.com') {
    tabs.push({ id: 'admin', label: 'Admin' });
  }

  const renderTab = () => {
    switch (activeTab) {
      case 'accueil': return <Accueil />;
      case 'actualites': return <Actualites />;
      case 'telex': return <Telex />;
      case 'conducteur': return <Conducteur />;
      case 'jeux': return <Jeux />;
      case 'appels': return <Appels />;
      case 'admin': return <Admin />;
      default: return <Accueil />;
    }
  };

  if (!user) return <Login />;

  return (
    <div
      style={{
        backgroundImage: "url('https://skyrock.fm/_next/static/media/maintenance.398af913.jpg')",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        minHeight: "100vh"
      }}
      className="text-white font-sans"
    >
      <header className="flex justify-between items-center px-6 py-4 bg-black/70">
        <img
          src="https://skyrock.fm/images/logo-skyrock.svg"
          alt="Skyrock Logo"
          className="h-12"
        />
        <div className="flex items-center gap-4">
          <h1 className="text-2xl font-bold">Intranet Skyrock</h1>
          <button onClick={handleLogout} className="text-sm bg-red-700 hover:bg-red-800 px-4 py-1 rounded">
            Se déconnecter
          </button>
        </div>
      </header>

      <nav className="flex flex-wrap gap-2 justify-center bg-black/60 p-4 shadow-md">
        {tabs.map(tab => (
          <button
            key={tab.id}
            onClick={() => setActiveTab(tab.id)}
            className={`px-4 py-2 rounded-full font-semibold text-sm uppercase transition-all ${activeTab === tab.id ? 'bg-white text-red-700' : 'bg-red-700 text-white hover:bg-red-800'}`}
          >
            {tab.label}
          </button>
        ))}
      </nav>

      <main className="p-6 bg-black/50 min-h-[70vh]">
        {renderTab()}
      </main>

      <footer className="text-center text-white/60 text-xs p-4 bg-black/70 border-t border-white/10">
        © 2025 Skyrock – Intranet réservé | Données confidentielles | Conforme au RGPD
      </footer>
    </div>
  );
};

export default App;
