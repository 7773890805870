// src/pages/Login.js
import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase';
import '../index.css';

export default function Login() {
  const [email, setEmail] = useState('steven@skyrock.com');
  const [password, setPassword] = useState('Morbihan56##');
  const [error, setError] = useState('');
  const [remember, setRemember] = useState(false);

  const handleLogin = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .catch(err => {
        setError("Email ou mot de passe incorrect.");
        console.error(err);
      });
  };

  return (
    <div
      className="h-screen bg-cover bg-center flex items-center justify-center"
      style={{ backgroundImage: "url('https://skyrock.fm/images/bg-partout.png')" }}
    >
      <form
        onSubmit={handleLogin}
        className="bg-black/70 text-white p-8 rounded-lg shadow-lg w-full max-w-md space-y-6 border border-white/10 backdrop-blur"
      >
        <div className="text-center">
          <img
            src="https://www.svgrepo.com/show/54507/skyrock-logo.svg"
            alt="Skyrock Logo"
            className="mx-auto w-24 h-24 mb-4"
          />
          <h2 className="text-2xl font-bold">Connexion Intranet</h2>
        </div>

        {error && <p className="text-red-400 text-center">{error}</p>}

        <div>
          <label className="text-sm text-white/70">Email</label>
          <input
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            className="w-full p-2 rounded bg-gray-800 text-white mt-1"
            required
          />
        </div>

        <div>
          <label className="text-sm text-white/70">Mot de passe</label>
          <input
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            className="w-full p-2 rounded bg-gray-800 text-white mt-1"
            required
          />
        </div>

        <div className="flex items-center gap-2 text-sm">
          <input
            type="checkbox"
            checked={remember}
            onChange={() => setRemember(!remember)}
            className="accent-blue-600"
          />
          <label>Se souvenir de moi</label>
        </div>

        <button
          type="submit"
          className="w-full bg-blue-600 hover:bg-blue-700 text-white py-2 rounded font-semibold"
        >
          Se connecter
        </button>
      </form>
    </div>
  );
}
