import React, { useState } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

export default function Appels() {
  const [appels, setAppels] = useState([]);
  const [form, setForm] = useState({ prenom: '', age: '', ville: '', sujet: '', numero: '' });

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleAddAppel = () => {
    if (form.prenom && form.numero) {
      setAppels([...appels, form]);
      setForm({ prenom: '', age: '', ville: '', sujet: '', numero: '' });
    }
  };

  const exportPDF = () => {
    const doc = new jsPDF();
    doc.text('Liste des Appels Auditeurs - Skyrock', 14, 16);
    doc.autoTable({
      startY: 20,
      head: [['Prénom', 'Age', 'Ville', 'Sujet', 'Numéro']],
      body: appels.map(a => [a.prenom, a.age, a.ville, a.sujet, a.numero]),
    });
    doc.save('appels_skyrock.pdf');
  };

  return (
    <div className="text-white max-w-4xl mx-auto px-6 py-8 space-y-6">
      <h2 className="text-3xl font-bold text-center">📞 Gestion des Appels</h2>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 bg-black/40 p-4 rounded">
        <input
          className="p-2 rounded bg-gray-800 text-white"
          name="prenom"
          placeholder="Prénom"
          value={form.prenom}
          onChange={handleChange}
        />
        <input
          className="p-2 rounded bg-gray-800 text-white"
          name="age"
          placeholder="Âge"
          value={form.age}
          onChange={handleChange}
        />
        <input
          className="p-2 rounded bg-gray-800 text-white"
          name="ville"
          placeholder="Ville"
          value={form.ville}
          onChange={handleChange}
        />
        <input
          className="p-2 rounded bg-gray-800 text-white"
          name="sujet"
          placeholder="Sujet de l'appel"
          value={form.sujet}
          onChange={handleChange}
        />
        <input
          className="p-2 rounded bg-gray-800 text-white"
          name="numero"
          placeholder="Numéro de téléphone"
          value={form.numero}
          onChange={handleChange}
        />
        <button
          onClick={handleAddAppel}
          className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded"
        >
          Ajouter
        </button>
      </div>

      {appels.length > 0 && (
        <>
          <div className="text-right">
            <button
              onClick={exportPDF}
              className="bg-green-600 hover:bg-green-700 text-white py-2 px-4 rounded"
            >
              Exporter en PDF
            </button>
          </div>

          <div className="space-y-3">
            {appels.map((appel, idx) => (
              <div key={idx} className="bg-white/10 p-4 rounded shadow">
                <p><strong>Prénom :</strong> {appel.prenom}</p>
                <p><strong>Âge :</strong> {appel.age} ans</p>
                <p><strong>Ville :</strong> {appel.ville}</p>
                <p><strong>Sujet :</strong> {appel.sujet}</p>
                <p><strong>Numéro :</strong> <a href={`tel:${appel.numero}`} className="text-blue-400 underline">{appel.numero}</a></p>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}
