import React, { useState } from 'react';
import jsPDF from 'jspdf';

const Jeux = () => {
  const jeuxDisponibles = [
    {
      id: 'switch',
      nom: 'Nintendo Switch 2',
      description: "Skyrock t'offre la NINTENDO SWITCH 2 en EXCLU tous les jours et 12 000 euros dans le Morning de Difool !",
      image: 'https://skyrock.fm/uploads2020/image/resize-crop/contests/contests-48.MTQwNWU3Mzg2ZjJjZmVkYTVkOGRmNjlkN2FjMDk0MGQ4ZDc2MmFiNzAyYjAxODdiZWFhOTg5NDg3OWIzNWY4NQ.jpg?resize_w=2880'
    },
    {
      id: 'morning1500',
      nom: '1500€ Morning Difool',
      description: 'Réponds Skyrock quand Difool t’appelle et gagne 1500€ tous les matins.',
      image: 'https://skyrock.fm/uploads2020/image/resize-crop/contests/contests-44.YmYzYmMwYTM4NWNhNmMzY2JiMTBiYmUyMTdlYTU3OTE1ZGY2Y2NkNGFhNzA4Y2Q1NWQ4MjQxMmY4YWQzYmZlNQ.jpg?resize_w=2880'
    }
  ];

  const [participants, setParticipants] = useState([]);
  const [nom, setNom] = useState('');
  const [ville, setVille] = useState('');
  const [numero, setNumero] = useState('');
  const [jeuSelectionne, setJeuSelectionne] = useState(jeuxDisponibles[0].id);

  const ajouterParticipant = () => {
    if (!nom || !ville || !numero) return alert('Remplis tous les champs');
    setParticipants([...participants, { nom, ville, numero, jeu: jeuSelectionne }]);
    setNom(''); setVille(''); setNumero('');
  };

  const tirageAuSort = (jeuId) => {
    const inscrits = participants.filter(p => p.jeu === jeuId);
    if (inscrits.length === 0) return alert('Aucun participant pour ce jeu.');
    const gagnant = inscrits[Math.floor(Math.random() * inscrits.length)];
    alert(`🎉 Gagnant (${jeuId}) : ${gagnant.nom} (${gagnant.ville})`);
  };

  const exporterPDF = () => {
    const doc = new jsPDF();
    doc.setFontSize(16);
    doc.text('Liste des Participants Jeux Skyrock', 10, 10);
    participants.forEach((p, i) => {
      doc.text(`${i + 1}. ${p.nom} (${p.ville}) - ${p.numero} [${p.jeu}]`, 10, 20 + i * 10);
    });
    doc.save('participants_jeux_skyrock.pdf');
  };

  return (
    <div className="text-white space-y-8">
      <h2 className="text-2xl font-bold">🎮 Jeux & Concours</h2>

      <div className="grid md:grid-cols-2 gap-4">
        {jeuxDisponibles.map(jeu => (
          <div key={jeu.id} className="bg-white/10 p-4 rounded shadow">
            <h3 className="text-xl font-bold mb-2">{jeu.nom}</h3>
            <img src={jeu.image} alt={jeu.nom} className="rounded-lg mb-2" />
            <p>{jeu.description}</p>
            <button onClick={() => tirageAuSort(jeu.id)} className="mt-2 px-4 py-2 rounded bg-yellow-400 text-black font-semibold">🎯 Tirage pour ce jeu</button>
          </div>
        ))}
      </div>

      <div className="bg-black/30 p-4 rounded space-y-4">
        <h3 className="text-lg font-semibold">📥 Ajouter un participant</h3>
        <input value={nom} onChange={e => setNom(e.target.value)} placeholder="Prénom" className="text-black p-2 rounded w-full" />
        <input value={ville} onChange={e => setVille(e.target.value)} placeholder="Ville" className="text-black p-2 rounded w-full" />
        <input value={numero} onChange={e => setNumero(e.target.value)} placeholder="Téléphone" className="text-black p-2 rounded w-full" />
        <select value={jeuSelectionne} onChange={e => setJeuSelectionne(e.target.value)} className="text-black p-2 rounded w-full">
          {jeuxDisponibles.map(j => <option key={j.id} value={j.id}>{j.nom}</option>)}
        </select>
        <button onClick={ajouterParticipant} className="bg-green-500 text-white font-bold px-4 py-2 rounded">✅ Ajouter</button>
      </div>

      <div className="bg-black/20 p-4 rounded">
        <h3 className="font-bold mb-2">👥 Participants</h3>
        <ul className="list-disc ml-6">
          {participants.map((p, i) => (
            <li key={i}>{p.nom} – {p.ville} – {p.numero} ({p.jeu})</li>
          ))}
        </ul>
        <button onClick={exporterPDF} className="mt-4 px-4 py-2 bg-blue-500 rounded text-white font-semibold">📄 Exporter PDF</button>
      </div>
    </div>
  );
};

export default Jeux;
