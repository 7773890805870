// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Configuration Firebase
const firebaseConfig = {
  apiKey: "AIzaSyB5uwuK_XMOKYHVFCY-MxRFbMyGOdVFhgA",
  authDomain: "intranet-skyrock.firebaseapp.com",
  projectId: "intranet-skyrock",
  storageBucket: "intranet-skyrock.appspot.com",
  messagingSenderId: "1092759415709",
  appId: "1:1092759415709:web:32fcdbfec4dbc13c411d74",
  measurementId: "G-1FMLY38CJJ"
};

// Initialisation Firebase
const app = initializeApp(firebaseConfig);

// Auth & Firestore
export const auth = getAuth(app);
export const db = getFirestore(app);
